"use client";

import TagManager, { TagManagerArgs } from "@sooro-io/react-gtm-module";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

export function GoogleTagManager() {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        initializeTagManager();
    }, []);

    useEffect(() => {        
        TagManager.dataLayer({
            dataLayer: {
                event: "virtual_page_view",
                page_location: `${pathname}${(searchParams.size > 0 ? "?" + searchParams.toString() : "")}`,
                page_title: window.document.title,
            }
        });
    }, [pathname, searchParams]);

    return <></>;
}

function initializeTagManager(args?: Partial<TagManagerArgs>) {
    const base: TagManagerArgs = {
        gtmId: "GTM-ND5TBC7",
    };

    TagManager.initialize({ ...base, ...args });
}