import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../assets/fonts/tt-norma-pro/woff/TT_Norms_Pro_Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/tt-norma-pro/woff/TT_Norms_Pro_Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-norma\"}],\"variableName\":\"norma\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../assets/fonts/sangbleu/woff/SangBleuRepublic-Regular-WebS.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/sangbleu/woff/SangBleuRepublic-Medium-WebS.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/sangbleu/woff/SangBleuRepublic-Bold-WebS.woff\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-sangbleu\"}],\"variableName\":\"sangbleu\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lang]/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/vercel/path0/src/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager"] */ "/vercel/path0/src/components/ga-events/GoogleTagManager.tsx");
